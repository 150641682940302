import "lazysizes";
import "typeface-merriweather";
import "typeface-montserrat";
import Json from "./newpageURL";

let currentStyleSet = null; // Tracks the currently loaded style set

// Function to preload and apply styles
const preloadStyles = (href) => {
  if (document.querySelector(`link[href="${href}"]`)) return;

  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = href;
  link.setAttribute("data-dynamic-style", "true");
  document.head.appendChild(link);
};

// Load critical styles before the app starts
export const onClientEntry = () => {

  // Ensure base styles are available before React mounts
  [
    "/css/new/fontStyle.css",
    "/css/new/variable.css",
    "/css/new/reset.css",
    "/css/new/common.css",
    "/css/new/module/button.css",
    "/css/new/module/modal.css",
    "/css/new/module/blogs.css",
    "/css/new/theme.css",
    "/css/new/module/header.css",
    "/css/new/module/pagination.css",
    "/css/new/module/react-tab.css",
    "/css/new/module/select.css",
    "/css/new/module/accordion.css",
    "/css/new/module/static.css",
    "/css/new/module/resources.css",
    "/css/new/module/thankyou.css"
  ].forEach(preloadStyles);
};

// Load styles dynamically on route updates
export const onRouteUpdate = ({ location }) => {
  const currentPath = location.pathname;

  // Remove previously loaded dynamic styles
  document.querySelectorAll("link[data-dynamic-style]").forEach(link => link.remove());

  const newStyleSet = Json.newURL.some(route => currentPath.includes(route)) ? "new" : "old";

  if (currentStyleSet !== newStyleSet) {
    currentStyleSet = newStyleSet;

    const stylesToLoad = newStyleSet === "new"
      ? [
        "/css/new/fontStyle.css",
        "/css/new/variable.css",
        "/css/new/reset.css",
        "/css/new/common.css",
        "/css/new/module/button.css",
        "/css/new/module/modal.css",
        "/css/new/module/blogs.css",
        "/css/new/theme.css",
        "/css/new/module/header.css",
        "/css/new/module/pagination.css",
        "/css/new/module/react-tab.css",
        "/css/new/module/select.css",
        "/css/new/module/accordion.css",
        "/css/new/module/static.css",
        "/css/new/module/resources.css",
        "/css/new/module/thankyou.css"
      ]
      : [
        "/css/old/common.css",
        "/css/old/style.css"
      ];

    stylesToLoad.forEach(preloadStyles);
  }
};
